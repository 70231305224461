import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components';

import API from '../../api/api';
import Title from '../reusables/Title';
import SteamProfile from './SteamProfile';


const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadein 3s;

    @media (max-width: 768px) {
        display: grid;
    }

`

const Box = styled.div`
  padding: 10px;
  margin: 5px;
  cursor: pointer;
`

const SteamFriends = () => {
    const [friends, setFriends] = useState();
    const { id } = useParams();

    useEffect(() => {
        API.GetFriends(id, res => {
            setFriends(res)
        })
    }, [id])

    return <>
        <Title>My first three Friends</Title>
        <Container>
            {
                friends && friends.friendslist.friends.sort((a,b) => a.friend_since - b.friend_since).slice(0, 3).map(friend => {
                return <Link 
                    key={friend.steamid}
                    to={{
                        pathname: '/' + friend.steamid 
                    }}
                >
                    <Box>
                        <SteamProfile key={friend.steamid} gameId={friend.steamid} />
                    </Box>
                </Link>
                })
            }
        </Container>
    </>
}

export default SteamFriends